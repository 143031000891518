import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import dynamic from "next/dynamic";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { MouseEventHandler, ReactNode } from "react";
import { CONTRACT_ADDRESS, OS_SLUG, WEBSITE_URL } from "../constants/env";
import EtherscanIcon from "../public/etherscan-light.svg";
import OpenseaIcon from "../public/opensea.svg";

const MintBox = dynamic(() => import("../components/MintBox"), { ssr: false });

export default function Home() {
  return (
    <>
      <Head>
        <title>ZombieGolbin</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="NFT, Free, Ethereum" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="min-h-screen w-full grid grid-cols-1 grid-rows-1">
        <div className="bkg w-full relative grid grid-cols-1 grid-rows-1 row-start-1 col-start-1 select-none -z-10">
          <img className="h-full w-full row-start-1 col-start-1 object-cover -z-10" src="/page.jpeg" alt="" />
          <div className="mask row-start-1 col-start-1 backdrop-blur-sm" />
        </div>

        <nav className="absolute top-0 w-full flex flex-row justify-end items-center p-4 gap-3">
          {[
            [`https://etherscan.io/address/${CONTRACT_ADDRESS}`, "Etherscan", <EtherscanIcon key={0} />],
            [`https://opensea.io/collection/${OS_SLUG}`, "Opensea", <OpenseaIcon key={1} />],
            ["https://twitter.com/Zombiegoblin_tf", "Twitter", <FontAwesomeIcon icon={faTwitter} color="#b37e56" key={2} />],
          ].map(([href, title, icon], idx) => (
            <a key={idx} className="grid place-items-stretch w-12 h-12 rounded-lg hover:animate-shaking" href={href as string} target="_blank" rel="noreferrer">
              <img className="row-start-1 col-start-1" src="/button.webp" alt={title as string} />
              <div className="row-start-1 col-start-1 p-3">{icon}</div>
            </a>
          ))}

          <ConnectButton.Custom>
            {(modal) => {
              let children: ReactNode;
              let onClick: MouseEventHandler;

              if (!modal.mounted || !modal.account || !modal.chain) {
                onClick = modal.openConnectModal;
                children = <>{modal.connectModalOpen ? "Connecting..." : "Connect Wallet"}</>;
              } else if (modal.chain.unsupported) {
                children = <>{modal.chainModalOpen ? "Switching" : "Switch Network"}</>;
                onClick = modal.openChainModal;
              } else {
                children = (
                  <>
                    {modal.account.displayName} - {modal.account.displayBalance}
                  </>
                );
                onClick = modal.openAccountModal;
              }

              return (
                <button
                  className={`button py-2 px-4 flex flex-row justify-center items-center gap-2 h-12 rounded-lg transition-all duration-300 border-2 border-black disabled:bg-gray-500 disabled:border-gray-400
                  ${
                    modal.chain?.unsupported === true
                      ? " bg-rose-700 hover:bg-rose-600 active:bg-rose-900 active"
                      : " bg-cyan-700 hover:bg-cyan-600 active:bg-cyan-900 active"
                  }`}
                >
                  <FontAwesomeIcon className="aspect-square h-full" icon={faWallet} color="white" />
                  <div className="text-white font-bold text-left" onClick={onClick}>
                    {children}
                  </div>
                </button>
              );
            }}
          </ConnectButton.Custom>
        </nav>

        <main className="row-start-1 col-start-1 flex flex-col justify-between items-start">
          <h1 className="my-12 px-24 w-full text-8xl text-slate-200 text-left font-bold select-none leading-none" style={{ textShadow: "0 2px 4px black" }}>
            ZombieGoblin
          </h1>
          <div className="ml-[10%]">
            <MintBox />
          </div>
          <div className="flex-grow" />
          <footer
            className="
            w-full
            flex flex-row items-center mx-4 my-2 gap-4 whitespace-nowrap text-amber-50 font-bold text-sm select-none
            before:content-[''] before:flex-grow before:h-0.5 before:bg-zinc-200 before:opacity-20
            after:content-[''] after:flex-grow after:h-0.5 after:bg-zinc-200 after:opacity-20
            "
          >
            Zoooooooooombie Production
          </footer>
        </main>
      </div>
    </>
  );
}
